import classes from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <small>
        Made with &#9749; and &#x2764; by{" "}
        <a target="_blank" rel="noreferrer" href="https://techzia.in/">
          Techzia
        </a>
      </small>
    </footer>
  );
};

export default Footer;
