import classes from "./UploadFile.module.scss";

const UploadFile = ({ onUpload }) => {
  return (
    <label htmlFor="csv" className="btn btn-mix text-white">
      <input
        type="file"
        name="csv"
        id="csv"
        accept=".csv"
        className={classes["file-input"]}
        onChange={onUpload}
      />
      Upload CSV
    </label>
  );
};

export default UploadFile;
