import axios from "../../axios";
import UploadFile from "../UploadFile/UploadFile";
import { JOURNAL_CREATE_URL } from "../../lib/api";

const CSVForm = ({ onFetch, onUpload }) => {
  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("csvFile", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(JOURNAL_CREATE_URL, formData, config)
      .then((response) => {
        const journalData = response?.data?.data?.journal;
        if (journalData) {
          onFetch(journalData);
        }
      })
      .catch((err) => {
        let errorMessage = err.message;
        const errorData = err?.response?.data;
        if (errorData) {
          errorMessage = errorData?.message || errorData?.data.csvFile[0];
        }
        alert(errorMessage);
      });
  };

  const fileChangeHandler = (event) => {
    const file = event.target.files && event.target.files[0];
    onFetch([]);
    if (file) {
      const SPLITTER = ".csv";
      const outputFileName =
        file.name.split(SPLITTER)[0] + "-journal" + SPLITTER;
      onUpload(outputFileName);
      uploadFile(file);
    }
  };

  return (
    <form className="m-2">
      <UploadFile onUpload={fileChangeHandler} />
    </form>
  );
};

export default CSVForm;
