import Div100vh from "react-div-100vh";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

const Layout = (props) => {
  return (
    <>
      <Header />
      <main>
        <Div100vh className="d-flex flex-column justify-content-center align-items-center">
          {props.children}
        </Div100vh>
      </main>
      <Footer />
    </>
  );
};

export default Layout;
