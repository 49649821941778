import { useState } from "react";
import CSVForm from "../CSVForm/CSVForm";
import CsvDownload from "react-json-to-csv";

const Journal = () => {
  const [fileName, setFileName] = useState("");
  const [journalData, setJournalData] = useState([]);

  const updateJournalData = (data) => {
    setJournalData(data);
  };

  const updateFileName = (name) => {
    setFileName(name);
  };

  return (
    <>
      <CSVForm onFetch={updateJournalData} onUpload={updateFileName} />
      {journalData.length !== 0 && (
        <CsvDownload
          className="btn btn-primary"
          data={journalData}
          filename={fileName}
        />
      )}
    </>
  );
};

export default Journal;
