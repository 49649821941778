import "./App.css";
import Journal from "./components/Journal/Journal";
import Layout from "./components/Layout/Layout";

function App() {
  return (
    <Layout>
      <Journal />
    </Layout>
  );
}

export default App;
