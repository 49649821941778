import classes from "./Header.module.scss";

const Header = () => {
  return (
    <header
      className={[
        classes.header,
        "w-100 text-center p-3 fs-4 d-flex justify-content-between",
      ].join(" ")}
    >
      <a className="navbar-brand" href="/">
        Trade Journal
      </a>
      <span>&copy;</span>
    </header>
  );
};

export default Header;
